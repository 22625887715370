import { createI18n } from '@yangss/vue3-i18n';

export const i18n = createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: {
      'en': {
        Prophet_head: "Prophet",
        Prophet_Tag: "Predicting future demand of the skills for careers in the future",
        Prophet_Description:"By our prophet service, we use an artificial intelligence system consuming a big amount of data to capture the trends of the skills that will be more demanded in the future. This service not only predicts “WHAT” but also predicts “WHEN” it will become a new demanded skill. To align with the graduation timeline of our students.",
        Prophet_tag:"#AI based predictor",
        
        Guidance_head:"Guidance",
        Guidance_Tag:"Guiding the best fit learning track for learners",
        Guidance_Description:"In reality we don’t judge fish by its ability to climb. By our guiding service we use an AI-powered matching algorithm to suggest the students what career is the best suite for them. When you register with us, the current transcript is mandatory because we need to know your background and skill to map with the on demand future.In the software development squad, even the one who doesn't know how to code has a place to work",
        Guidance_tag:"#Customized Your Learning Path",
      
        Academic_head:"Academic",
        Academic_Tag:"Money has no matter, follow your dreams, follow your learning path.",
        Academic_Description:"After you get your learning path, you can get into our courses without any cost. We provide you, lectures, laboratories and real world experiences with our partners*.Only one rule is, you can enroll in 1 course at a time. We believe in the power of focus. *The real world experiences courses have to work on-site with SME doing digital transformation projects. Some places will hire you as a contract employee.",
        Academic_tag:"#Free courses with real world experiences.",
      
        Trust_head:"Trust",
        Trust_Tag:"We are not providing you certificates, but will generate your professional resume.",
        Trust_Description:"After you finish the learning path, we believe that your skills and experiences are enough to work as a professional. However, a professional resume and interview preparation are also important. Our team will help you prepare for this process.",
        Trust_tag:"#We trust in your skills and experiences",
      
        Kaching_head:"Kaching",
        Kaching_Tag:"The place where your resume is valued.Description: If you are qualified, why do you have to show your resume for free?Kaching is the art gallery for resumes, gathering head-hunters, recruiters and your future employers who really want to know you and pay for an interview with you.Your hard work will pay off.",
        Kaching_Description:"if you are qualified, why do you have to show your resume for free?Kaching is the art gallery for resumes, gathering head-hunters, recruiters and your future employers who really want to know you and pay for an interview with you.Your hard work will pay off.",
        Kaching_tag:"#Resume marketplace with NFT technology",
      
        Login_Recruiter:"Recruiter",
        Login_Name:"Name*",
        Login_LastName:"Last Name*",
        Login_Email:"Personal email*",
        Login_Tel:"Tel*",
        Login_CompanyEmail:"Company’s email",
        Login_Linkedin:"Linkedin",
      
        Login_Student:"Student",
        Login_PersonalEmail:"Personal email*",
        Login_University:"University*",
        Login_Faculty:"Faculty*",
        Login_Major:"Major*",
        Login_EstimationOfGraduationDate:"Estimation of graduation date*",
        Login_InstitutesEmail:"Institutes’s email",
        Login_Facebook:"Facebook",
      
      
        Quiz_Math_1: "1.x = min(5, 10, 25) <br> y = max(5, 10, 25) <br>What is x+y",
        Quiz_Math_1_Ans: "30",
      
        Quiz_Math_2: "2.x = abs(-7.25) <br> y = 4**3 <br>What is x+y",
        Quiz_Math_2_Ans: "71.25",
      
        Quiz_Math_3: "3.x = ceil(1.4) <br> y = floor(1.4) <br>What is x-y",
        Quiz_Math_3_Ans: "1",
      
        Quiz_Math_4: "4.x = sqrt(64) <br> What is x",
        Quiz_Math_4_Ans: "8",
      
        Quiz_Math_5: "5.What is Thailand timezone",
        Quiz_Math_5_choice_A: "A.GMT+5",
        Quiz_Math_5_choice_B: "B.GMT+6",
        Quiz_Math_5_choice_C: "C.GMT+7",
        Quiz_Math_5_choice_D: "D.GMT+8",
        Quiz_Math_5_Ans: "C.GMT+7",
      
      
        Quiz_Developer_1: "1. Which one is compiler language?",
        Quiz_Developer_1_choice_A: "A.Python",
        Quiz_Developer_1_choice_B: "B.HTML",
        Quiz_Developer_1_choice_C: "C.C++",
        Quiz_Developer_1_choice_D: "D.CSS",
        Quiz_Developer_1_Ans: "C.C++",
      
        Quiz_Developer_2: "2. Python is ____ language.",
        Quiz_Developer_2_choice_A: "A.Object Oriented Programming",
        Quiz_Developer_2_choice_B: "B.Functional Programming",
        Quiz_Developer_2_Ans: "A.Object Oriented Programming",
      
        Quiz_Developer_3: "3. Scala is ___ language.",
        Quiz_Developer_3_choice_A: "A.Object Oriented Programming",
        Quiz_Developer_3_choice_B: "B.Functional Programming",
        Quiz_Developer_3_choice_C: "C.Both",
        Quiz_Developer_3_Ans: "C.Both",
      
        Quiz_Developer_4: "4. Compiler language can run ___ interpreter language.",
        Quiz_Developer_4_choice_A: "A.Faster than",
        Quiz_Developer_4_choice_B: "B.Same speed as",
        Quiz_Developer_4_choice_C: "C.Slower than",
        Quiz_Developer_4_Ans: "A.Faster than",
      
        Quiz_Developer_5: "5. ________ is the person who designs the architecture of software-related systems in the company.",
        Quiz_Developer_5_choice_A: "A.Frontend",
        Quiz_Developer_5_choice_B: "B.Backend",
        Quiz_Developer_5_choice_C: "C.DevOps",
        Quiz_Developer_5_choice_D: "D.Software Architect",
        Quiz_Developer_5_Ans: "D.Software Architect",
      
        Quiz_Developer_6: "6. ____ engineers use html, css, javascript and some frameworks such as vue.js, react to develop some part of the website.",
        Quiz_Developer_6_choice_A: "A.Frontend",
        Quiz_Developer_6_choice_B: "B.Backend",
        Quiz_Developer_6_choice_C: "C.DevOps",
        Quiz_Developer_6_Ans: "A.Frontend",
      
        
        Quiz_Developer_7: "7. ____ engineers use django or flask, fastAPI framework (python), node with pug and express for developing RestAPI and some parts of the website.",
        Quiz_Developer_7_choice_A: "A.Frontend",
        Quiz_Developer_7_choice_B: "B.Backend",
        Quiz_Developer_7_choice_C: "C.DevOps",
        Quiz_Developer_7_Ans: "B.Backend",
      
        Quiz_Developer_8: "8. ___ engineers are the people who bridge the gap of development and operations, take care of the version of the program.",
        Quiz_Developer_8_choice_A: "A.DevOps",
        Quiz_Developer_8_choice_B: "B.Fullstack",
        Quiz_Developer_8_choice_C: "C.Site Reliability Engineer",
        Quiz_Developer_8_Ans: "A.DevOps",
      
        Quiz_Developer_9: "9. ________ is the person who focuses on Availability of the overall software systems.",
        Quiz_Developer_9_choice_A: "A.DevOps",
        Quiz_Developer_9_choice_B: "B.Fullstack",
        Quiz_Developer_9_choice_C: "C.Site Reliability Engineer",
        Quiz_Developer_9_Ans: "C.Site Reliability Engineer",
      
        Quiz_Developer_10: "is the person who designs the architecture of data-related systems in the company.",
        Quiz_Developer_10_Ans: "Data Architect",
        Quiz_Developer_11: "is the person who focuses on Availability of the overall data systems.",
        Quiz_Developer_11_Ans: "Data Platform engineer",
        Quiz_Developer_12: " is the person who creates the extract transform load pipeline feeding into the data warehouse.",
        Quiz_Developer_12_Ans: "Data Engineer",
        Quiz_Developer_13: " is the person who uses the data for finding an insight using statistical tools",
        Quiz_Developer_13_Ans: "Data Analyst",
        Quiz_Developer_14: " is the person who uses the data from the past data to create the prediction or classification models.",
        Quiz_Developer_14_Ans: "Data Scientist",
        Quiz_Developer_15: " is the person who focuses on feeding the data into the prediction/classification model to make an automated prediction or classification system.",
        Quiz_Developer_15_Ans: "Machine learning Engineer",
        Quiz_Developer_16: " is the person who focuses on developing the automated prediction and classification model update system by monitoring the model’s post deployment performance",
        Quiz_Developer_16_Ans: "MLOps Engineer",
      
        Quiz_Developer_10_16_choice_A: "Data Platform engineer",
        Quiz_Developer_10_16_choice_B: "Data Architect",
        Quiz_Developer_10_16_choice_C: "Data Engineer",
        Quiz_Developer_10_16_choice_D: "Data Analyst",
        Quiz_Developer_10_16_choice_E: "Data Scientist",
        Quiz_Developer_10_16_choice_F: "Machine learning Engineer",
        Quiz_Developer_10_16_choice_G: "MLOps Engineer",
        
        popUpHome: "We are processing your document, our customer support team will contact you soon, Please make sure the information you filled in is correct."
},
      'th': {
        
        Prophet_head: "Prophet",
        Prophet_Tag: "ระบบคาดการณ์สกิลที่สำคัญในงานแห่งโลกอนาคต",
        Prophet_Description:"Prophet เป็นระบบปัญญาประดิษฐ์ที่สร้างขึ้นเพื่อทำนายว่า “อะไร” จะเป็นทักษะที่จำเป็นในอนาคตและ ทักษะนั้น จะมาเป็นเทรนด์ในประเทศไทย “เมื่อไหร่” เพื่อที่นักเรียนของเราจะได้เตรียมตัวเรียนรู้ทักษะเหล่านั้น ได้ทันท่วงที โดยระบบปัญญาประดิษฐ์นี้ถูกสร้างจากฐานข้อมูลขนาดใหญ่ขอเทรนด์ในการจ้างงานทั่วโลก",
        Prophet_tag:"#AI based predictor",

        Guidance_head:"Guidance",
        Guidance_Tag:"แนะนำชุดการเรียนรู้ที่เหมาะสมกับคุณผู้เรียน เพื่อให้ผลลัพธ์ออกมาดีที่สุด",
        Guidance_Description:"ในโลกความเป็นจริง ทุกคนมีความสามารถในการเรียนรู้เรื่องเดียวกัน ไม่เท่ากัน ดังนั้นหาก ชุดการเรียนรู้ที่จะนำไปสู่อาชีพที่ไม่เหมาะกับผู้เรียน อาจจะไรค่า ดังนั้น นอกจากเราจะทำนายเทรนด์ของทักษะที่สำคัญของโลกอนาคตแล้ว การจับคู่ทักษะปัจจุบันของผู้เรียน กับ ชุดการเรียนที่เหมาะสม จะพาผู้เรียนไปสู่ความสำเร็จทางการงานมากกว่าสนใจแค่ อะไรกำลังเป็นที่ต้องการของตลาดแรงงานในโลกการทำงานของกลุ่มนักพัฒนาเทตโนโลยี คนที่เขียนโค้ดไม่เก่ง ก็สามารถทำหน้าที่สำคัญอื่นๆได้",
        Guidance_tag:"#Customized Your Learning Path",

        Academic_head:"Academic",
        Academic_Tag:"ความฝันของคุ สร้างได้ที่นี่กับเรา แบบไม่มีค่าใช้จ่าย",
        Academic_Description:"หลังจากที่คุณได้รับชุดการเรียนรู้แล้ว คุณสามารถเรียนได้ แบบไม่มีค่าใช้จ่ายใดๆทั้งสิ้น เราเตรียม คอร์สเรียน, แลปออนไลน์ และยังมีพาร์ทเนอร์ SME ของเราที่พร้อมเป็นสถานที่ มอบประสบการณ์การทำงานจริงอีกด้วย*เราเชื่อในพลังของการโฟกัส ข้อจำกัดเดียวของคุณคือสามารถเรียนได้ฟรีทีละคอร์ส เท่านั้น*การทำงานกับ SME อาจมีบางแห่งต้องไปทำงานที่สถานที่จริง ช่วยพาร์ทเนอร์ ทำ digital transformation บางที่อาจจะจ้างเป็นสัญญาจ้าง บางที่อาจจะเป็นการฝึกงานแบบไม่ได้เงิน ขึ้นอยู่กับผลการเรียนและบริษัทพาร์ทเนอร์ที่เปิดรับ ขณะนั้น",
        Academic_tag:"#Free courses with real world experiences.",

        Trust_head:"Trust",
        Trust_Tag:"เรียนกับเราไม่ได้ใบประกาศ แต่เราจะมอบ resume ที่พร้อมใช้สมัครงานให้คุณ",
        Trust_Description:"หลังจากที่คุณเรียนจบ และผ่านทุกคอร์สในชุดการเรียนรู้ที่เราจัดให้คุณแล้ว เรามั่นใจว่า คุณ พร้อมที่จะทำงานในแบบมืออาชีพ แต่ เรซูเม่และการสัมภาษณ์ยังเป็นขั้นตอนสำคัญที่เหลืออยู่ เราสัญญาว่าจะช่วยคุณเตรียมตัวอย่างเต็มที่",
        Trust_tag:"#We trust in your skills and experiences",

        Kaching_head:"Kaching",
        Kaching_Tag:"ที่นี่เรซูเม่คุณมีค่า",
        Kaching_Description:"ถ้าคุณเจ๋งพอ งานจะมาหาคุณ และเรซูเม่ของคุณมีมูลค่า ทำไมต้องให้คนอื่นดูฟรีๆหละที่นี่เรามี Kaching ที่เป็นเหมือนแกลอรี่สำหรับ resume ของนักเรียนที่ผ่านการเรียนกับเราเปรียบเสมือนงานศิลปะที่รอให่คนมาประมูล เรามีการรวบรวมผู้สนใจจะจ้างงาน นักเรียนคุณภาพเหล่านี้ไว้ที่นี่ หากเรซูเม่ของคุณ เตะตา ผู้จ้างงาน และผู้จ้างงานอยากสัมภาษณ์คุณ ผู้จ้างงานจะซื้อ resume นี้เพื่อได้รับสิทธิ์ในการ ติดต่อหาคุณ ที่คุณพยายามมาทั้งหมด เป็นมูลค่า ตั้งแต่ยังไม่เริ่มงานแหนะ",
        Kaching_tag:"#Resume marketplace with NFT technology",

        Login_Recruiter:"Recruiter",
        Login_Name:"ชื่อ",
        Login_LastName:"นามสกุล",
        Login_Email:"อีเมลล์ส่วนตัว",
        Login_Tel:"เบอร์โทรศัพท์",
        Login_CompanyEmail:"อีเมลล์สถาบัน หรือ บริษัท",
        Login_Linkedin:"Linkedin",

        Login_Student:"Student",
        Login_PersonalEmail:"อีเมลล์ส่วนตัว",
        Login_University:"สถาบันกรศึกษา",
        Login_Faculty:"คณะ",
        Login_Major:"ภาค",
        Login_EstimationOfGraduationDate:"เดือน/ปี ของวันจบการศึกษา",
        Login_Institutes_Email:"อีเมลล์สถาบัน",
        Login_Facebook:"Facebook",
       
        Quiz_Math_1: "1.x = min(5, 10, 25) <br> y = max(5, 10, 25) <br>What is x+y",
        Quiz_Math_1_Ans: "30",
      
        Quiz_Math_2: "2.x = abs(-7.25) <br> y = 4**3 <br>What is x+y",
        Quiz_Math_2_Ans: "71.25",
      
        Quiz_Math_3: "3.x = ceil(1.4) <br> y = floor(1.4) <br>What is x-y",
        Quiz_Math_3_Ans: "1",
      
        Quiz_Math_4: "4.x = sqrt(64) <br> What is x",
        Quiz_Math_4_Ans: "8",
      
        Quiz_Math_5: "5.What is Thailand timezone",
        Quiz_Math_5_choice_A: "A.GMT+5",
        Quiz_Math_5_choice_B: "B.GMT+6",
        Quiz_Math_5_choice_C: "C.GMT+7",
        Quiz_Math_5_choice_D: "D.GMT+8",
        Quiz_Math_5_Ans: "C.GMT+7",
      
      
        Quiz_Developer_1: "1. Which one is compiler language?",
        Quiz_Developer_1_choice_A: "A.Python",
        Quiz_Developer_1_choice_B: "B.HTML",
        Quiz_Developer_1_choice_C: "C.C++",
        Quiz_Developer_1_choice_D: "D.CSS",
        Quiz_Developer_1_Ans: "C.C++",
      
        Quiz_Developer_2: "2. Python is ____ language.",
        Quiz_Developer_2_choice_A: "A.Object Oriented Programming",
        Quiz_Developer_2_choice_B: "B.Functional Programming",
        Quiz_Developer_2_Ans: "A.Object Oriented Programming",
      
        Quiz_Developer_3: "3. Scala is ___ language.",
        Quiz_Developer_3_choice_A: "A.Object Oriented Programming",
        Quiz_Developer_3_choice_B: "B.Functional Programming",
        Quiz_Developer_3_choice_C: "C.Both",
        Quiz_Developer_3_Ans: "C.Both",
      
        Quiz_Developer_4: "4. Compiler language can run ___ interpreter language.",
        Quiz_Developer_4_choice_A: "A.Faster than",
        Quiz_Developer_4_choice_B: "B.Same speed as",
        Quiz_Developer_4_choice_C: "C.Slower than",
        Quiz_Developer_4_Ans: "A.Faster than",
      
        Quiz_Developer_5: "5. ________ is the person who designs the architecture of software-related systems in the company.",
        Quiz_Developer_5_choice_A: "A.Frontend",
        Quiz_Developer_5_choice_B: "B.Backend",
        Quiz_Developer_5_choice_C: "C.DevOps",
        Quiz_Developer_5_choice_D: "D.Software Architect",
        Quiz_Developer_5_Ans: "D.Software Architect",
      
        Quiz_Developer_6: "6. ____ engineers use html, css, javascript and some frameworks such as vue.js, react to develop some part of the website.",
        Quiz_Developer_6_choice_A: "A.Frontend",
        Quiz_Developer_6_choice_B: "B.Backend",
        Quiz_Developer_6_choice_C: "C.DevOps",
        Quiz_Developer_6_Ans: "A.Frontend",
      
        
        Quiz_Developer_7: "7. ____ engineers use django or flask, fastAPI framework (python), node with pug and express for developing RestAPI and some parts of the website.",
        Quiz_Developer_7_choice_A: "A.Frontend",
        Quiz_Developer_7_choice_B: "B.Backend",
        Quiz_Developer_7_choice_C: "C.DevOps",
        Quiz_Developer_7_Ans: "B.Backend",
      
        Quiz_Developer_8: "8. ___ engineers are the people who bridge the gap of development and operations, take care of the version of the program.",
        Quiz_Developer_8_choice_A: "A.DevOps",
        Quiz_Developer_8_choice_B: "B.Fullstack",
        Quiz_Developer_8_choice_C: "C.Site Reliability Engineer",
        Quiz_Developer_8_Ans: "A.DevOps",
      
        Quiz_Developer_9: "9. ________ is the person who focuses on Availability of the overall software systems.",
        Quiz_Developer_9_choice_A: "A.DevOps",
        Quiz_Developer_9_choice_B: "B.Fullstack",
        Quiz_Developer_9_choice_C: "C.Site Reliability Engineer",
        Quiz_Developer_9_Ans: "C.Site Reliability Engineer",
      
        Quiz_Developer_10: "is the person who designs the architecture of data-related systems in the company.",
        Quiz_Developer_10_Ans: "Data Architect",
        Quiz_Developer_11: "is the person who focuses on Availability of the overall data systems.",
        Quiz_Developer_11_Ans: "Data Platform engineer",
        Quiz_Developer_12: " is the person who creates the extract transform load pipeline feeding into the data warehouse.",
        Quiz_Developer_12_Ans: "Data Engineer",
        Quiz_Developer_13: " is the person who uses the data for finding an insight using statistical tools",
        Quiz_Developer_13_Ans: "Data Analyst",
        Quiz_Developer_14: " is the person who uses the data from the past data to create the prediction or classification models.",
        Quiz_Developer_14_Ans: "Data Scientist",
        Quiz_Developer_15: " is the person who focuses on feeding the data into the prediction/classification model to make an automated prediction or classification system.",
        Quiz_Developer_15_Ans: "Machine learning Engineer",
        Quiz_Developer_16: " is the person who focuses on developing the automated prediction and classification model update system by monitoring the model’s post deployment performance",
        Quiz_Developer_16_Ans: "MLOps Engineer",
      
        Quiz_Developer_10_16_choice_A: "Data Platform engineer",
        Quiz_Developer_10_16_choice_B: "Data Architect",
        Quiz_Developer_10_16_choice_C: "Data Engineer",
        Quiz_Developer_10_16_choice_D: "Data Analyst",
        Quiz_Developer_10_16_choice_E: "Data Scientist",
        Quiz_Developer_10_16_choice_F: "Machine learning Engineer",
        Quiz_Developer_10_16_choice_G: "MLOps Engineer",

        popUpHome: "เรากำลังตรวจสอบข้อมูลของท่านอยู่ เจ้าหน้าที่จะทำการตอบกลับไปทันทีที่ตรวจสอบเสร็จ กรุณากรอกข้อมูลให้ถูกต้องครบถ้วนด้วยนะคะ"



      }
    }
  })