<template>
    <div class="iot-features-area ptb-80 bg-f7fafd" id="Kaching">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 iot-features-content">
                    <h3>{{ $t('Kaching_head') }}</h3>
                    <p>{{ $t('Kaching_Description') }}</p>
                
                    <a @click= "clickGetStarted" class="btn btn-primary">Get Started</a>
                </div>

                <div class="col-lg-6 iot-features-image">
                    <img :src= "KachingImg" alt="image">
                    
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import UserService from '@/utils/userAPI.js';
export default {
    name: 'Kaching',
    data(){
        return{
            KachingImg : require("@/assets/img/talDemy/certification.png"),
            KachingImgSize : 400,
        }
    },
    methods:{
        clickGetStarted(){
            const Type = this.$store.state.Type;          
            const testStatus = this.$store.state.testStatus;

            if(this.$store.state.token){
                UserService.getInfo(this.$store.state.token)
                .then((response) =>{

                    if(response.status === 500){
                        window.location = "/NotFound500";
                        return ;
                    }

                    if(Type == "Recruiter" ){
                        if (typeof (response.data.info.personalEmail) != "undefined"){                      
                            this.$store.commit("setRegisterStatus",true);                                    
                        }
                        else{ // not info
                             this.$store.commit("setRegisterStatus",false); 
                             window.location= '/info';
                             return ; 
                        }
                        
                    }
                    else if(Type == "Student"){   
                        if (typeof (response.data.info.personalEmail) != "undefined"){
                            this.$store.commit("setRegisterStatus",true);
                        }
                        else{ // not info
                            this.$store.commit("setRegisterStatus",false); 
                             window.location= '/info';
                             return ; 
                        }
                    }
                   
                    })
                    .catch((error) => {console.log(error);
                        if(error.response.status == 500){
                            window.location = "/NotFound500";
                            return ;
                        } 
                     })  

                
               .then(() =>{
                    const popUpData = this.$i18n.t('popUpHome');

                    if(this.$store.state.registerStatus == true && Type == "Recruiter"){
                        // alert("เนื้อหานี้ มีไว้สำหรับนักเรียนเท่านั้น");                      
                         alert(popUpData);
                    }
                
                    else if(this.$store.state.registerStatus == true  && Type == "Student" && testStatus == false){
                        // alert("ทางทีมงานกำลังจัดชุดการเรียนรู้ที่เหมาะสมกับคุณกรุณาอดใจรอสักครู่\nหากอยากรู้คะแนนสอบติดต่อ support@taldemy.com");
                        alert(popUpData);
                    }
                    else if(this.$store.state.registerStatus == true  && Type == "Student" && testStatus == true){
                        // alert("ทางทีมงานกำลังจัดชุดการเรียนรู้ที่เหมาะสมกับคุณกรุณาอดใจรอสักครู่\nหากอยากรู้คะแนนสอบติดต่อ support@taldemy.com");
                        alert(popUpData);
                    }
                });
                
            }
            else{
                // if(this.$store.state.registerStatus == false  && Type == null){
                window.location= '/whoAreYou';  
            }
        }           
        //    default:
        //     window.location= '/whoAreYou';  
        
    }    
}
</script>
