<template>
    <div>
        <Prophet></Prophet>
        <Guidance></Guidance>
        <Academic></Academic>
        <Trust></Trust>
        <Kaching></Kaching>
    </div>
</template>

<script>
import Prophet from './talDemy/Prophet';
import Guidance from './talDemy/Guidance';
import Academic from './talDemy/Academic';
import Trust from './talDemy/Trust';
import Kaching from './talDemy/Kaching';

export default {
    name: 'talDemy',
    components: {
        Prophet,
        Guidance,
        Academic,
        Trust,
        Kaching
    }
}
</script>