+<template>
    <!-- Start Navbar Area -->
    <header id="header" :class="['headroom', {'is-sticky': isSticky}]">
        <div class="startp-nav">
            <div class="container">
                <nav class="navbar navbar-expand-md navbar-light">
                    <router-link class="navbar-brand" to="/home" onclick="javascript:window.location='#';">
                        <img :src= "logo" alt="logo" :width= "widthImageLogo" :height = "heightImageLogo">
                    </router-link>

                    <b-navbar-toggle target="navbarSupportedContent"></b-navbar-toggle>

                    <b-collapse class="collapse navbar-collapse mean-menu" id="navbarSupportedContent" is-nav>
                        <ul class="navbar-nav nav ml-auto">
                            <li class="nav-item">
                                <router-link to="/home" onclick="javascript:window.location='#';" class="nav-link">Prophet</router-link>
                            </li>
                            <li class="nav-item">             
                                <router-link to="/home" onclick="javascript:window.location='#Guidance';" class="nav-link">Guidance</router-link>                           
                            </li>
                            <li class="nav-item">
                                <router-link to="/home" onclick="javascript:window.location='#Academic';" class="nav-link">Academic</router-link>
                            </li>                                        
                            <li class="nav-item">                
                                <router-link to="/home" onclick="javascript:window.location='#Trust';" class="nav-item">Trust</router-link>                       
                            </li>
                            <li class="nav-item">
                                <router-link to="/home" onclick="javascript:window.location='#Kaching';" class="nav-link">Kaching</router-link>
                            </li>
                                                    
                        </ul>
                    </b-collapse>

                    <div class="others-option">                   
                            <a v-show = "!this.$store.state.login_State" href="/login" class="btn btn-light">Login</a>
                            <a v-show = "!this.$store.state.login_State" @click= "cickSignUp" class="btn btn-primary">Signup</a>

                            <ul v-show = "this.$store.state.login_State" class="navbar-nav nav ml-auto">
                             <li class="nav-item"><a href="#" class="nav-link"><img class = "rounded" :src = "this.$store.state.userImgURl"> <vue-feather type="chevron-down"></vue-feather></a>
                                <ul class="dropdown_menu">
                                    <li class="nav-item">
                                        <a class="nav-link" href="/info">Account</a>
                                        <a class="nav-link" @click= "handleSignOut">Log Out</a>
                                    </li>                
                                </ul>
                            </li> 
                            </ul>
                           
                    </div>
                    <div class="language">
                        <ul class="navbar-nav nav ml-auto">
                            <li class="nav-item"><a class="nav-link"><img class="nav-item" :src= "language" :height = "heightImageLanguage"></a>                       
                             <ul class="dropdown_menu">
                                    <li class="nav-item">
                                        <a class="nav-link" @click= "ENGLanguage" style= "cursor: pointer;">ENG</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" @click= "THLanguage" style= "cursor: pointer;">TH</a>
                                    </li>                  
                                </ul>
                            </li>
                        </ul> 
            
                    </div>
                     
                </nav>
            </div> 
        </div>
    </header>
    <!-- End Navbar Area -->
</template>

<script>
import {inject} from "vue";
import { useI18n } from '@yangss/vue3-i18n';
import store from '@/store/index.js';
export default {
    
    name: 'Header',
    data(){
        return {
            isSticky: false,
            logo: require('@/assets/img/talDemy/Transparent-Logo.png'),
            language: require('@/assets/img/talDemy/Language.png'),
            widthImageLogo: "156",
            heightImageLogo: "90",
            widthImageLanguage: "60",
            heightImageLanguage: "60",
            // ImgUser: this.$store.state.userImgURl,

        }
    },

    mounted() {
        const that = this;
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY;
            // eslint-disable-next-line no-console
            if(scrollPos >= 100){
                that.isSticky = true;
            } else {
                that.isSticky = false;
            }
        })
    },

    computed: {
        shoppingCart(){
            return this.$store.state.cart;
        },
    },

    setup() {
    const Vue3GoogleOauth = inject('Vue3GoogleOauth');
    const {locale} = useI18n();
  
    return {
         THLanguage: () => { locale.value = 'th'; store.commit("setLanguage","th");},
         ENGLanguage: () => { locale.value = 'en'; store.commit("setLanguage","en");},
         Vue3GoogleOauth
    }
  },
  methods: {
        chooseLanguage(language){
            const {locale} = useI18n();
            locale.value = language;
            // console.log(language);            
        },
       cickSignUp(){
            // this.$router.push('/contact')
            // if(this.$store.state.registerStatus == false ){
            window.location= '/whoAreYou';
            // }
       },
       async handleSignOut() {
           
            try {
                await this.$gAuth.signOut();            
            } catch (error) {
                // console.log(error);
             }
             
            this.$store.commit("setLogin",false);
            this.$store.commit("setRegisterStatus",false);
            this.$store.commit("setUserID",null);
            this.$store.commit("setType",null);
            this.$store.commit("testStatus",false);
            this.$store.commit("userImgURl","");
            this.$store.commit("setToken",null);

            window.location.replace("/home"); 
        },

    },
}
</script>


<style>
.language{
    display: flex;
    justify-content: left;
}
.rounded {
  flex: none;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  object-fit: cover;
}
</style>