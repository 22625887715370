<template>
    <div class = "rootLogin">       
        <!-- Start Page Title -->
        <div class="page-title-area">
          <div class="container" >
                <div class="row" style="justify-content: center ;">
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="box">
                            <img :src = "talDemylogo" :height= "heightImageLogo">
                            <h1>Log in</h1>
                            <p>Let's prepare your digital career with us</p> 
                            <br>                  
                            <div class= "loginIcon">
                                <img class = "GoogleLoginImg" :src = "GoogleLogin"  @click= 'handleSignIn'>                           
                            </div>
                            <br>
                            <br>
                            <h6>Don't have an account? <a href= "/whoAreYou">Sign up</a></h6>  
                        </div>               
                    </div>
                </div>
			</div>			
			<div class="shape1"><img src="../../assets/img/shape1.png" alt="shape"></div>
			<div class="shape2 rotateme"><img src="../../assets/img/shape2.svg" alt="shape"></div>
			<div class="shape3"><img src="../../assets/img/shape3.svg" alt="shape"></div>
			<div class="shape4"><img src="../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape5"><img src="../../assets/img/shape5.png" alt="shape"></div>
			<div class="shape6 rotateme"><img src="../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape7"><img src="../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape8 rotateme"><img src="../../assets/img/shape2.svg" alt="shape"></div>
        </div>
        <!-- End Page Title -->
       

        <!-- Start Contact Info Area -->
		<section class="contact-info-area ptb-80">           
        </section>  
        <!-- End Contact Info Area -->

    </div>
</template>

<script>
import {inject, ref} from "vue"
import UserService from '@/utils/userAPI.js';
export default {
    name: 'whoAreYou',
    data() {
    return {
        GetuserId: "",
        GetauthId:"",
        Getsource:"",
        GetfirstName:"",
        GetlastName:"",
        Gettelephone:"",
        Getemail:"",
        Getrole: "",
        LinkedInURL:"https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=987654321&scope=r_liteprofile%20r_emailaddress&client_id=86bbnyyn2br31w&redirect_uri=http://localhost:5000/",
        StudentLogin: require('@/assets/img/talDemy/student_4.png'),
        RecruiterLogin: require('@/assets/img/talDemy/recruitment_1.png'),   
        GoogleLogin: require('@/assets/img/talDemy/btn_google_signin_dark_normal_web.png'),
        LinkedInLogin: require('@/assets/img/talDemy/LinkedIn-Blue-26@2x.png'),
        ImgLoginSize: "128",
        ImgGoogleSize: "64",
        ImgLinkedInSize: "64",
        talDemylogo: require('@/assets/img/talDemy/Wordpress Transparent.png'),
        widthImageLogo: "156",
        heightImageLogo: "90",
     }
    },

    methods: {
        setUserType(type){
            this.isOpen = true;
            this.userType = type;
        },

        async handleSignIn() {
        try {
            const googleUser = await this.$gAuth.signIn();

            if (!googleUser) {
            return null;
            }
            
                // this.GetuserId = googleUser.BasicProfile().getName();
                // this.Getsource = ;
                // this.GetfirstName = googleUser.BasicProfile().getGivenName();
                // this.GetlastName = googleUser.BasicProfile().getFamilyName();
                // this.Gettelephone = ;
                this.Getemail = googleUser.getBasicProfile().getEmail();
                // this.Getrole =;   
                const id_token = googleUser.getAuthResponse().id_token;
           UserService.login({
                authId: id_token,
                source:"google"
            })
            .then((response) => {
                if(response.status == 500){
                    window.location = "/NotFound500";
                } 

                console.log(response);
                this.$store.commit("setToken",response.data.token);
                this.$store.commit("setLogin",true);
                this.$store.commit("setType",response.data.info.role);

                if(response.status == 201){
                    window.location = "/info";
                }else if(response.status == 202){
                    this.$store.commit("setRegister",true);
                    window.location = "/home"
                }
            },(error) =>{
                console.log(error)
                if(error.response.status == 403){
                    alert("User does not exist. Please register first.");
                    return;
                }
                let path = "/NotFound"+error.response.status;
                window.location = path;
            } );     

        } catch (error) {
                console.log(error);
                
        }
      
        },

         async handleSignOut() {
            try {
                await this.$gAuth.signOut();
                // console.log(this.$gAuth.signOut);
                this.user = '';
            } catch (error) {
                // console.log(error);
             }   
        },

        popitup(url) {
            const newwindow= window.open(url, 'name', 'toolbar=1,scrollbars=1,location=1,statusbar=0,menubar=1,resizable=1,width=800,height=600');
             if (window.focus) {
                newwindow.focus();

            }
            return false;
        }

    },

    setup() {  
        const isOpen = ref(false);
        const Vue3GoogleOauth = inject("Vue3GoogleOauth");
        return {
            isOpen,
            Vue3GoogleOauth,
        } 
    },


}
</script>


<style>
.ptb-80 > .loginIcon{
    display: flex;
    justify-content: center ;
  }

.LinkedInLoginImg{
    margin-right: 20px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.loginIcon{
     cursor: pointer;
}

.box {
  text-align: center;
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
          box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  background: #ffffff;
  padding: 30px;
}

</style>