import { createWebHistory, createRouter } from "vue-router";

// All Pages
import talDemy from "../components/landing-pages/talDemy.vue";
import whoAreYouSignUp from "../components/custom/whoAreYou.vue";
import Quiz from "../components/custom/Quiz.vue";
import WhoAreYouLogin from "../components/custom/WhoAreYouLogin.vue";
import CreditsSection from "../components/custom/CreditsSection.vue";
import NotFound403 from "../components/not-found/NotFound403.vue";
import NotFound404 from "../components/not-found/NotFound404.vue";
import NotFound500 from "../components/not-found/NotFound500.vue";
import AccountInfo from "../components/custom/accountInfo.vue";
const routes = [
  { path: '/', redirect: '/home' },
  { path: "/home", component: talDemy },
  { path: "/login",component: WhoAreYouLogin},
  { path: "/whoAreYou", component: whoAreYouSignUp },
  { path: "/Quiz", component: Quiz},
  { path: "/CreditsSection", component: CreditsSection},
  { path: "/info", component: AccountInfo},
  { path: "/NotFound403", component: NotFound403},
  { path: "/NotFound404", component: NotFound404},
  { path: "/NotFound500", component: NotFound500},
  { path: "/:pathMatch(.*)*", redirect: "/NotFound404" },
  
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
