<template>   
    <div class="page-title-area">  
      <h1>Credits Section</h1>
      <br>
        <div class = "CreditsSection">
            <div class = "box">
                <div>Icon made from <a href="http://www.onlinewebfonts.com/icon">Icon Fonts</a> is licensed by CC BY 3.0</div>  
                <div><a href="https://www.flaticon.com/free-icons/student" title="student icons">Student icons created by Freepik - Flaticon</a></div>
                <div><a href="https://www.flaticon.com/free-icons/recruitment" title="recruitment icons">Recruitment icons created by Eucalyp - Flaticon</a></div>
                <div><a href="https://www.flaticon.com/free-icons/university" title="university icons">University icons created by Mehwish - Flaticon</a></div>
                <div><a href="https://www.flaticon.com/free-icons/linkedin" title="linkedin icons">Linkedin icons created by Freepik - Flaticon</a></div>
                <div><a href="https://www.flaticon.com/free-icons/certificate" title="certificate icons">Certificate icons created by bukeicon - Flaticon</a></div>
                <div><a href="https://www.flaticon.com/free-icons/trust" title="trust icons">Trust icons created by Freepik - Flaticon</a></div>
                <div><a href="https://www.flaticon.com/free-icons/trust" title="trust icons">Trust icons created by Freepik - Flaticon</a></div>
            <br>
            </div>
            <div class="shape1"><img src="../../assets/img/shape1.png" alt="shape"></div>
            <div class="shape2 rotateme"><img src="../../assets/img/shape2.svg" alt="shape"></div>
            <div class="shape3"><img src="../../assets/img/shape3.svg" alt="shape"></div>
            <div class="shape4"><img src="../../assets/img/shape4.svg" alt="shape"></div>
            <div class="shape5"><img src="../../assets/img/shape5.png" alt="shape"></div>
            <div class="shape6 rotateme"><img src="../../assets/img/shape4.svg" alt="shape"></div>
            <div class="shape7"><img src="../../assets/img/shape4.svg" alt="shape"></div>
            <div class="shape8 rotateme"><img src="../../assets/img/shape2.svg" alt="shape"></div>
        </div>
    </div>
    
</template>

<script>
export default {
  name: 'CreditsSection',
//   data(){
//     return{
//     };
//   },
}
</script>

<style>
.CreditsSection { 
    display: flex;
    justify-content:center; 
    text-align: justify;
}



</style>