<template>
    <div class="iot-main-banner">
        <div class="container">
            <div class="iot-banner-content">                  
                <h2>{{ $t('Prophet_head') }}</h2>                
                <p>{{ $t('Prophet_Description') }}</p>

                <a @click= "clickGetStarted" class="btn btn-primary" id="Guidance">Get Started</a>                
            </div>

            <div class="iot-banner-image" >
                <img src="../../../assets/img/1.png" alt="image">
                <img src="../../../assets/img/iot-banner-image/2.png" alt="image">
            </div>

            <div class="shape1"><img src="../../../assets/img/shape1.png" alt="shape"></div>
            <div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
            <div class="shape3"><img src="../../../assets/img/shape3.svg" alt="shape"></div>
            <div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
            <div class="shape5"><img src="../../../assets/img/shape5.png" alt="shape"></div>
            <div class="shape6 rotateme"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
            <div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
            <div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>

        </div>
    </div>
</template>

<script>
import UserService from '@/utils/userAPI.js';
export default {
    name: 'Prophet',   
    methods:{
        clickGetStarted(){
             const Type = this.$store.state.Type;          
            const testStatus = this.$store.state.testStatus;

            if(this.$store.state.token){
                UserService.getInfo(this.$store.state.token)
                .then((response) =>{

                    if(response.status === 500){
                        window.location = "/NotFound500";
                        return ;
                    }

                    if(Type == "Recruiter" ){
                        if (typeof (response.data.info.personalEmail) != "undefined"){                      
                            this.$store.commit("setRegisterStatus",true);                                    
                        }
                        else{ // not info
                             this.$store.commit("setRegisterStatus",false); 
                             window.location= '/info';
                             return ; 
                        }
                        
                    }
                    else if(Type == "Student"){   
                        if (typeof (response.data.info.personalEmail) != "undefined"){
                            this.$store.commit("setRegisterStatus",true);
                        }
                        else{ // not info
                            this.$store.commit("setRegisterStatus",false); 
                             window.location= '/info';
                             return ; 
                        }
                    }
                   
                    })
                    .catch((error) => {console.log(error);
                        if(error.response.status == 500){
                            window.location = "/NotFound500";
                            return ;
                        } 
                    })  

               .then(() =>{
                    const popUpData = this.$i18n.t('popUpHome');

                    if(this.$store.state.registerStatus == true && Type == "Recruiter"){
                        // alert("เนื้อหานี้ มีไว้สำหรับนักเรียนเท่านั้น");                      
                         alert(popUpData);
                    }
                
                    else if(this.$store.state.registerStatus == true  && Type == "Student" && testStatus == false){
                        // alert("ทางทีมงานกำลังจัดชุดการเรียนรู้ที่เหมาะสมกับคุณกรุณาอดใจรอสักครู่\nหากอยากรู้คะแนนสอบติดต่อ support@taldemy.com");
                        alert(popUpData);
                    }
                    else if(this.$store.state.registerStatus == true  && Type == "Student" && testStatus == true){
                        // alert("ทางทีมงานกำลังจัดชุดการเรียนรู้ที่เหมาะสมกับคุณกรุณาอดใจรอสักครู่\nหากอยากรู้คะแนนสอบติดต่อ support@taldemy.com");
                        alert(popUpData);
                    }
                });
                
            }
            else{
                // if(this.$store.state.registerStatus == false  && Type == null){
                window.location= '/whoAreYou';  
            }
        }           
        //    default:
        //     window.location= '/whoAreYou';  
        
    }
}
</script>