<template>
     <div class="page-title-area">
        <!-- Start Contact Area -->
        <section class="contact-area ptb-80 pt-0">
            <div class="container">
                <div class="section-title">
                    <h2>{{$t("Login_Student")}}</h2>
                    <!-- <div class="bar"></div> -->
                    <!-- <p>Anything On your Mind. We’ll Be Glad To Assist You!</p> -->
                </div>

                <div class="row h-100 justify-content-center align-items-center">              
                    <div class="col-lg-6 col-md-12">
                        <form id="contactForm" @submit="onSubmit">
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required data-error="Please enter your name" :placeholder= 'GetI18nData("Login_Name")'  v-model="firstName">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <input type="text" name= "LastName" id= "LastName" class= "form-control" required data-error= "Please enter your LastName" :placeholder= 'GetI18nData("Login_LastName")'  v-model= "lastName">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
										<input type="email" name="PersonalEmail" id="PersonalEmail" class="form-control" required data-error="Please enter your PersonalEmail" :placeholder= 'GetI18nData("Login_PersonalEmail")' v-model= "personalEmail">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>

                                 <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" required data-error="Please enter your number" class="form-control" :placeholder= 'GetI18nData("Login_Tel")' v-model= "phoneNumber">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>

                               <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
										<input type="text" name="University" id="University" class="form-control" required data-error="Please enter your University" :placeholder= 'GetI18nData("Login_University")' v-model= "university">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
										<input type="text" name="Faculty" id="Faculty" class="form-control" required data-error="Please enter your Faculty" :placeholder= 'GetI18nData("Login_Faculty")' v-model= "faculty">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
										<input type="text" name="Major" id="Major" class="form-control" required data-error="Please enter your Major" :placeholder= 'GetI18nData("Login_Major")' v-model= "major">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
										<input type="date" name="EstimationOfGraduationDate" id="EstimationOfGraduationDate" class="form-control" required data-error="Please enter your Estimation Of Graduation Date" :placeholder= 'GetI18nData("Login_EstimationOfGraduationDate")' v-model= "estimationOfGraduationDate">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
										<input type="email" name="InstitutesEmail" id="InstitutesEmail" class="form-control" required data-error="Please enter your Institutes Email" :placeholder= 'GetI18nData("Login_InstitutesEmail")' v-model= "institutesEmail">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
										<input type="text" name="Facebook" id="Facebook" class="form-control" required data-error="Please enter your Facebook" :placeholder= 'GetI18nData("Login_Facebook")' v-model= "facebook">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
                                <input type="submit" value="Save" class="btn btn-primary"/>

                                <div class="shape1"><img src="../../assets/img/shape1.png" alt="shape"></div>
                                <div class="shape2 rotateme"><img src="../../assets/img/shape2.svg" alt="shape"></div>
                                <div class="shape3"><img src="../../assets/img/shape3.svg" alt="shape"></div>
                                <div class="shape4"><img src="../../assets/img/shape4.svg" alt="shape"></div>
                                <div class="shape5"><img src="../../assets/img/shape5.png" alt="shape"></div>
                                <div class="shape6 rotateme"><img src="../../assets/img/shape4.svg" alt="shape"></div>
                                <div class="shape7"><img src="../../assets/img/shape4.svg" alt="shape"></div>
                                <div class="shape8 rotateme"><img src="../../assets/img/shape2.svg" alt="shape"></div>  
          
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Contact Area -->
    </div>
</template>

<script>
import UserService from '@/utils/userAPI.js';
export default {
    name: 'studentForm',
    data() {
        return {
            firstName: "",
            lastName: "",
            personalEmail:"",
            phoneNumber:"",
            university:"",
            faculty:"",
            major:"",
            estimationOfGraduationDate:"",
            institutesEmail:"",
            facebook:"",          
        }
    },
    beforeMount(){
        console.log("mounting");
        this.fetchInfo();
    },
        methods:{
             GetI18nData(cmd){
            return this.$i18n.t(cmd);     
            },
            fetchInfo(){
                if(this.$store.state.token){
                    UserService.getInfo(this.$store.state.token).then((response) =>{
                        console.log('response',response);
                        if(response.status != 200){
                            let path = "/NotFound"+response.status;
                            window.location = path;
                            return ;
                        }
                        this.$store.commit("setToken",response.data.token);
                        this.firstName = response.data.info.firstName;
                        this.lastName = response.data.info.lastName;
                        this.personalEmail = response.data.info.personalEmail;
                        this.phoneNumber = response.data.info.telephone;
                        this.university = response.data.info.university;
                        this.faculty = response.data.info.faculty;
                        this.major = response.data.info.major;
                        this.estimationOfGraduationDate = response.data.info.gradDate;
                        this.institutesEmail = response.data.info.uniEmail;
                        this.facebook = response.data.info.facebook;
                    },() => window.location = "/NotFound500");
                }
            },
            onSubmit(e){
                e.preventDefault();
                console.log();
                UserService.update(this.$store.state.token,{
                    firstName: this.firstName,
                    lastName: this.lastName,
                    personalEmail:this.personalEmail,
                    telephone:this.phoneNumber,
                    university:this.university,
                    faculty:this.faculty,
                    major:this.major,
                    gradDate:this.estimationOfGraduationDate,
                    uniEmail:this.institutesEmail,
                    facebook:this.facebook,
                    role:this.$store.state.Type
                }).then((response) => {
                    this.$store.commit("setToken",response.data.token);
                    this.$store.commit("setRegisterStatus",true);
                    //alert('Info updated successfully');
                    window.location = '/home';
                })
                .catch(() => window.location = "/NotFound500")     
            }
        },        
    }
</script>