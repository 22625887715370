<template>
    <footer class="footer-area bg-f7fafd">
        <div class="container">
            <div class="row" style= "justify-content:space-evenly;">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <div class="logo">
                            <router-link to="/home">
                                <!-- <img src="../../assets/img/logo.png" alt="logo" /> -->
                                <img :src= "logo" alt="logo" :width= "widthImageLogo" :height = "heightImageLogo">
                            </router-link>
                        </div>
                        <p>Let's prepare your digital career with us.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-footer-widget pl-5">
                        <h3>Services</h3>
                        <ul class="list">
                            <li><a href="#">Prophet</a></li>
                            <li><a href="#Guidance">Guidance</a></li>
                            <li><a href="#Academic">Academic</a></li>
                            <li><a href="#Trust">Trust</a></li>
                            <li><a href="#Kaching">Kaching</a></li>
                        </ul>
                    </div>
                </div>

                <!-- <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <h3>Support</h3>
                        <ul class="list">
                            <li><a href="#">FAQ's</a></li>
                            <li><a href="#">Privacy Policy</a></li>
                            <li><a href="#">Terms & Condition</a></li>
                            <li><a href="/CreditsSection">Credits Section</a></li>
                            <li><a href="#">Contact Us</a></li>
                        </ul>
                    </div>
                </div> -->

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <h3>Contact</h3>
                        
                        <ul class="footer-contact-info">
                            <li><vue-feather type="map-pin"></vue-feather> 168/199 Indy westgate, Bangrak pattna, Bangbuathong, Nonthaburi 11110</li>
                            <li><vue-feather type="mail"></vue-feather> Email: <a href="#">taldemy.edu@gmail.com</a></li>
                            <li><vue-feather type="phone-call"></vue-feather> Phone: <a href="#">0994158785</a></li>
                        </ul>
                        <ul class="social-links">
                            <li><a href="https://www.facebook.com/taldemyinc/" target="_blank" class="facebook"><vue-feather type="facebook"></vue-feather></a></li>                                        
                            <li><a href="https://www.instagram.com/taldemy/" target="_blank" class="instagram"><vue-feather type="instagram"></vue-feather></a></li>
                            <li><a href="https://www.linkedin.com/company/taldemy" target="_blank" class="linkedin"><vue-feather type="linkedin"></vue-feather></a></li>
                            <a href="https://medium.com/@taldemy.edu" target="_blank"><img class= "Mlogo" :src= "Mlogo" :width= "MlogoWidthImageLogo" :height = "MlogoheightImageLogo"></a>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="copyright-area">
                        <p>Copyright &copy; {{currentYear}} talDemy. All Rights Reserved</p>
                    </div>
                </div>
            </div>
        </div>

        <img src="../../assets/img/map.png" class="map" alt="map">
        <div class="shape1"><img src="../../assets/img/shape1.png" alt="shape"></div>
        <div class="shape8 rotateme"><img src="../../assets/img/shape2.svg" alt="shape"></div>

        
        <div 
            @click="scrollToTop()" 
            :class="['back-to-top-btn', {'go-top': isTop}]"
        >
            <vue-feather type="arrow-up"></vue-feather>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    data (){
        return {
            isTop: false,
            currentYear: new Date().getFullYear(),
            logo: require('@/assets/img/talDemy/Transparent-Logo-small.png'),
            language: require('@/assets/img/talDemy/Language.png'),
            widthImageLogo: "140",
            heightImageLogo: "60",
            Mlogo: require('@/assets/img/talDemy/Mlogo_32px.png'),
            MlogoWidthImageLogo: "32",
            MlogoheightImageLogo: "32",
            widthImageLanguage: "60",
            heightImageLanguage: "60",

        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
    },
    mounted(){
        const that = this
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY
            if(scrollPos >= 100){
                that.isTop = true
            } else {
                that.isTop = false
            }
        })
    }
}
</script>

<style>
.Mlogo{
    margin-bottom: 4px;
    cursor: pointer;
}

#talDemyFooter{
    display: flex;
    justify-content:space-around;
}
</style>

