import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Toaster from "@meforma/vue-toaster";
import VueFeather from "vue-feather";
import GAuth from 'vue3-google-oauth2';
import {i18n} from './i18n_messages.js';
import "./assets/custom.scss";

const app = createApp(App).use(i18n).use(router).use(store).use(Toaster);
const gAuthOptions = { clientId: '428503082816-ppm121lamdassrlevu5o62m5lo9hke3t.apps.googleusercontent.com', scope: 'email', prompt: 'consent',plugin_name: 'PLUGIN',}
app.use(GAuth, gAuthOptions)
app.component(VueFeather.name, VueFeather).mount("#app");

