<template>
     <div class="page-title-area">
        <!-- Start Contact Area -->
        <section class="contact-area ptb-80 pt-0">
            <div class="container">
                <div class="section-title">
                    <h2>{{$t("Login_Recruiter")}}</h2>
                    <!-- <div class="bar"></div> -->
                    <p>Anything On your Mind. We’ll Be Glad To Assist You!</p>
                </div>

                <div class="row h-100 justify-content-center align-items-center">              
                    <div class="col-lg-6 col-md-12">
                        <form id="contactForm" @submit="onSubmit">
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required data-error="Please enter your name" :placeholder= 'GetI18nData("Login_Name")' v-model="firstName">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <input type="text" name= "LastName" id= "LastName" class= "form-control" required data-error= "Please enter your LastName" :placeholder= 'GetI18nData("Login_LastName")'  v-model= "lastName">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
										<input type="email" name="email" id="email" class="form-control" required data-error="Please enter your email" :placeholder= 'GetI18nData("Login_Email")' v-model= "email">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>

                                 <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" required data-error="Please enter your number" class="form-control" :placeholder= 'GetI18nData("Login_Tel")' v-model= "phoneNumber">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>

                               <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
										<input type="email" name="CompanyEmail" id="CompanyEmail" class="form-control" required data-error="Please enter your CompanyEmail" :placeholder= 'GetI18nData("Login_CompanyEmail")' v-model= "businessEmail">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
										<input type="text" name="Linkedin" id="Linkedin" class="form-control" required data-error="Please enter your Linkedin" :placeholder= 'GetI18nData("Login_Linkedin")' v-model= "linkedin">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
                                
                                <input type="submit" value="Save" class="btn btn-primary"/>

                                <div class="shape1"><img src="../../assets/img/shape1.png" alt="shape"></div>
                                <div class="shape2 rotateme"><img src="../../assets/img/shape2.svg" alt="shape"></div>
                                <div class="shape3"><img src="../../assets/img/shape3.svg" alt="shape"></div>
                                <div class="shape4"><img src="../../assets/img/shape4.svg" alt="shape"></div>
                                <div class="shape5"><img src="../../assets/img/shape5.png" alt="shape"></div>
                                <div class="shape6 rotateme"><img src="../../assets/img/shape4.svg" alt="shape"></div>
                                <div class="shape7"><img src="../../assets/img/shape4.svg" alt="shape"></div>
                                <div class="shape8 rotateme"><img src="../../assets/img/shape2.svg" alt="shape"></div>  
                                  
           
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Contact Area -->
    </div>
</template>

<script>
import UserService from '@/utils/userAPI.js';

export default {
    name: 'recruiterForm',
   data() {
    return {
        firstName: "",
        lastName: "",
        email:"",
        phoneNumber:"",
        businessEmail:"",
        linkedin:"",
     }
    },
    beforeMount(){
        this.fetchInfo();
    },
    methods:{
        GetI18nData(cmd){
        return this.$i18n.t(cmd);     
        },
        fetchInfo(){
            if(this.$store.state.token){
            UserService.getInfo(this.$store.state.token).then((response) =>{
            console.log(response);
            if(response.status != 200){
                let path = "/NotFound"+response.status;
                window.location = path;
                return ;
            }
                  
                this.$store.commit("setToken",response.data.token);
                this.firstName = response.data.info.firstName;
                this.lastName = response.data.info.lastName;
                this.email = response.data.info.personalEmail;
                this.phoneNumber = response.data.info.telephone;
                this.businessEmail = response.data.info.businessEmail;
                this.linkedin = response.data.info.linkedin;
                },() => window.location = "/NotFound500");
            }
        },
        onSubmit(e){
            e.preventDefault();
            console.log();
            UserService.update(this.$store.state.token,{
                firstName: this.firstName,
                lastName: this.lastName,
                personalEmail:this.email,
                telephone:this.phoneNumber,
                businessEmail:this.businessEmail,
                linkedin:this.linkedin,
                role:this.$store.state.Type
            })
            .then((response) => {
                this.$store.commit("setToken",response.data.token);
                this.$store.commit("setRegisterStatus",true);
                //alert('Info updated successfully');
                window.location = '/home';
            })
            .catch(() => window.location = "/NotFound500")     
            }
}

}
</script>