<template>
 <div class="container">  
    <div calss ="QuizView">
      <br><br><br><br><br><br><br><br>
      <h1>Math</h1>

      <p v-html = "$t('Quiz_Math_1')"></p>
        <input type ="text" v-on:input= "QuizMath1_Ans"/>
      <br><br>

      <p v-html = "$t('Quiz_Math_2')"></p>
        <input type ="text" v-on:input= "QuizMath2_Ans"/>
      <br><br>

      <p v-html = "$t('Quiz_Math_3')"></p>
        <input type ="text" v-on:input= "QuizMath3_Ans"/>
      <br><br>

      <p v-html = "$t('Quiz_Math_4')"></p>
        <input type ="text" v-on:input= "QuizMath4_Ans"/>
      <br><br>

      <p v-html = "$t('Quiz_Math_5')"></p>
      <p v-html = "QuizMath_Ans_value_Ans_5"></p>
        <input type= "radio" :id= "GetI18nData('Math',5,'A')" :value= "GetI18nData('Math',5,'A')" v-model= "QuizMath_Ans_value_Ans_5" />
        <label :for= "GetI18nData('Math',5,'A')">{{$t('Quiz_Math_5_choice_A')}}</label>
        <br>
        <input type= "radio" :id=  "GetI18nData('Math',5,'B')" :value= "GetI18nData('Math',5,'B')" v-model= "QuizMath_Ans_value_Ans_5" />
        <label :for= "GetI18nData('Math',5,'B')">{{$t('Quiz_Math_5_choice_B')}}</label>
        <br>
        <input type= "radio" :id= "GetI18nData('Math',5,'C')" :value=  "GetI18nData('Math',5,'C')" v-model= "QuizMath_Ans_value_Ans_5" />
        <label :for= "GetI18nData('Math',5,'C')">{{$t('Quiz_Math_5_choice_C')}}</label>
        <br>
        <input type= "radio" :id= "GetI18nData('Math',5,'D')" :value= "GetI18nData('Math',5,'D')" v-model= "QuizMath_Ans_value_Ans_5" />
        <label :for= "GetI18nData('Math',5,'D')">{{$t('Quiz_Math_5_choice_D')}}</label>
        <br>
      <br><br>

      <h1>Developer</h1>

    <p v-html = "$t('Quiz_Developer_1')"></p>
        <input type="radio" :id=  "GetI18nData('Developer',1,'A')" :value= "GetI18nData('Developer',1,'A')" v-model= "QuizDeveloper_Ans_value.Ans_1" />
        <label :for = "GetI18nData('Developer',1,'A')">{{$t('Quiz_Developer_1_choice_A')}}</label>
        <br>
        <input type="radio" :id= "GetI18nData('Developer',1,'B')" :value= "GetI18nData('Developer',1,'B')" v-model= "QuizDeveloper_Ans_value.Ans_1" />
        <label :for= "GetI18nData('Developer',1,'B')">{{$t('Quiz_Developer_1_choice_B')}}</label>
        <br>
        <input type="radio" :id= "GetI18nData('Developer',1,'C')" :value= "GetI18nData('Developer',1,'C')" v-model= "QuizDeveloper_Ans_value.Ans_1" />
        <label :for= "GetI18nData('Developer',1,'C')">{{$t('Quiz_Developer_1_choice_C')}}</label>
        <br>
        <input type="radio" :id= "GetI18nData('Developer',1,'D')" :value= "GetI18nData('Developer',1,'D')" v-model= "QuizDeveloper_Ans_value.Ans_1" />
        <label :for= "GetI18nData('Developer',1,'D')">{{$t('Quiz_Developer_1_choice_D')}}</label>
        <br>
      <br><br>

      <p v-html = "$t('Quiz_Developer_2')"></p>
        <input type="radio" :id= "GetI18nData('Developer',2,'A')" :value= "GetI18nData('Developer',2,'A')" v-model= "QuizDeveloper_Ans_value.Ans_2" />
        <label :for= "GetI18nData('Developer',2,'A')">{{$t('Quiz_Developer_2_choice_A')}}</label>
        <br>
        <input type="radio" :id= "GetI18nData('Developer',2,'B')" :value= "GetI18nData('Developer',2,'B')" v-model= "QuizDeveloper_Ans_value.Ans_2" />
        <label :for= "GetI18nData('Developer',2,'B')">{{$t('Quiz_Developer_2_choice_B')}}</label>
        <br>
      <br><br>

      <p v-html = "$t('Quiz_Developer_3')"></p>
        <input type="radio" :id= "GetI18nData('Developer',3,'A')" :value= "GetI18nData('Developer',3,'A')" v-model= "QuizDeveloper_Ans_value.Ans_3" />
        <label :for= "GetI18nData('Developer',3,'A')">{{$t('Quiz_Developer_3_choice_A')}}</label>
        <br>
        <input type="radio" :id= "GetI18nData('Developer',3,'B')" :value= "GetI18nData('Developer',3,'B')" v-model= "QuizDeveloper_Ans_value.Ans_3" />
        <label :for= "GetI18nData('Developer',3,'B')">{{$t('Quiz_Developer_3_choice_B')}}</label>
        <br>
        <input type="radio" :id= "GetI18nData('Developer',3,'C')" :value= "GetI18nData('Developer',3,'C')" v-model= "QuizDeveloper_Ans_value.Ans_3" />
        <label :for= "GetI18nData('Developer',3,'C')">{{$t('Quiz_Developer_3_choice_C')}}</label>
        <br>
      <br><br>

      <p v-html = "$t('Quiz_Developer_4')"></p>
        <input type="radio" :id= "GetI18nData('Developer',4,'A')" :value= "GetI18nData('Developer',4,'A')" v-model= "QuizDeveloper_Ans_value.Ans_4" />
        <label :for= "GetI18nData('Developer',4,'A')">{{$t('Quiz_Developer_4_choice_A')}}</label>
        <br>
        <input type="radio" :id= "GetI18nData('Developer',4,'B')" :value= "GetI18nData('Developer',4,'B')" v-model= "QuizDeveloper_Ans_value.Ans_4" />
        <label :for= "GetI18nData('Developer',4,'B')">{{$t('Quiz_Developer_4_choice_B')}}</label>
        <br>
        <input type="radio" :id= "GetI18nData('Developer',4,'C')" :value= "GetI18nData('Developer',4,'C')" v-model= "QuizDeveloper_Ans_value.Ans_4" />
        <label :for= "GetI18nData('Developer',4,'C')">{{$t('Quiz_Developer_4_choice_C')}}</label>
        <br>
      <br><br>

      <p v-html = "$t('Quiz_Developer_5')"></p>
        <input type="radio" :id= "GetI18nData('Developer',5,'A')" :value= "GetI18nData('Developer',5,'A')" v-model= "QuizDeveloper_Ans_value.Ans_5" />
        <label :for= "GetI18nData('Developer',5,'A')">{{$t('Quiz_Developer_5_choice_A')}}</label>
        <br>
        <input type="radio" :id= "GetI18nData('Developer',5,'B')" :value= "GetI18nData('Developer',5,'B')" v-model= "QuizDeveloper_Ans_value.Ans_5" />
        <label :for= "GetI18nData('Developer',5,'B')">{{$t('Quiz_Developer_5_choice_B')}}</label>
        <br>
        <input type="radio" :id= "GetI18nData('Developer',5,'C')" :value= "GetI18nData('Developer',5,'C')" v-model= "QuizDeveloper_Ans_value.Ans_5" />
        <label :for= "GetI18nData('Developer',5,'C')">{{$t('Quiz_Developer_5_choice_C')}}</label>
        <br>
        <input type="radio" :id= "GetI18nData('Developer',5,'D')" :value= "GetI18nData('Developer',5,'D')" v-model= "QuizDeveloper_Ans_value.Ans_5" />
        <label :for= "GetI18nData('Developer',5,'D')">{{$t('Quiz_Developer_5_choice_D')}}</label>
        <br>
      <br><br>

      <p v-html = "$t('Quiz_Developer_6')"></p>
        <input type="radio" :id= "GetI18nData('Developer',6,'A')" :value= "GetI18nData('Developer',6,'A')" v-model= "QuizDeveloper_Ans_value.Ans_6" />
        <label for= "GetI18nData('Developer',6,'A')">{{$t('Quiz_Developer_6_choice_A')}}</label>
        <br>
        <input type="radio" :id= "GetI18nData('Developer',6,'B')" :value= "GetI18nData('Developer',6,'B')" v-model= "QuizDeveloper_Ans_value.Ans_6" />
        <label :for= "GetI18nData('Developer',6,'B')">{{$t('Quiz_Developer_6_choice_B')}}</label>
        <br>
        <input type="radio" :id= "GetI18nData('Developer',6,'C')" :value= "GetI18nData('Developer',6,'C')" v-model= "QuizDeveloper_Ans_value.Ans_6" />
        <label :for= "GetI18nData('Developer',6,'C')">{{$t('Quiz_Developer_6_choice_C')}}</label>
        <br>
      <br><br>

      <p v-html = "$t('Quiz_Developer_7')"></p>
      <p v-html = "QuizDeveloper_Ans_value.Ans_7"></p>
        <input type="radio" :id= "GetI18nData('Developer',7,'A')" :value= "GetI18nData('Developer',7,'A')" v-model= "QuizDeveloper_Ans_value.Ans_7" />
        <label :for= "GetI18nData('Developer',7,'A')">{{$t('Quiz_Developer_7_choice_A')}}</label>
        <br>
        <input type="radio" :id= "GetI18nData('Developer',7,'B')" :value= "GetI18nData('Developer',7,'B')" v-model= "QuizDeveloper_Ans_value.Ans_7" />
        <label :for= "GetI18nData('Developer',7,'B')">{{$t('Quiz_Developer_7_choice_B')}}</label>
        <br>
        <input type="radio" :id= "GetI18nData('Developer',7,'C')" :value= "GetI18nData('Developer',7,'C')" v-model= "QuizDeveloper_Ans_value.Ans_7" />
        <label :for= "GetI18nData('Developer',7,'C')">{{$t('Quiz_Developer_7_choice_C')}}</label>
        <br>
      <br><br>

      <p v-html = "$t('Quiz_Developer_8')"></p>
        <input type= "radio" :id= "GetI18nData('Developer',8,'A')" :value= "GetI18nData('Developer',8,'A')" v-model= "QuizDeveloper_Ans_value.Ans_8" />
        <label :for= "GetI18nData('Developer',8,'A')">{{$t('Quiz_Developer_8_choice_A')}}</label>
        <br>
        <input type= "radio" :id= "GetI18nData('Developer',8,'B')" :value= "GetI18nData('Developer',8,'B')" v-model= "QuizDeveloper_Ans_value.Ans_8" />
        <label :for= "GetI18nData('Developer',8,'B')">{{$t('Quiz_Developer_8_choice_B')}}</label>
        <br>
        <input type= "radio" :id= "GetI18nData('Developer',8,'C')" :value= "GetI18nData('Developer',8,'C')" v-model= "QuizDeveloper_Ans_value.Ans_8" />
        <label :for= "GetI18nData('Developer',8,'C')">{{$t('Quiz_Developer_8_choice_C')}}</label>
        <br>
      <br><br>

      <p v-html = "$t('Quiz_Developer_9')"></p>
        <input type="radio" :id= "GetI18nData('Developer',9,'A')" :value= "GetI18nData('Developer',9,'A')" v-model= "QuizDeveloper_Ans_value.Ans_9" />
        <label :for= "GetI18nData('Developer',9,'A')">{{$t('Quiz_Developer_9_choice_A')}}</label>
        <br>
        <input type="radio" :id= "GetI18nData('Developer',9,'B')" :value= "GetI18nData('Developer',9,'B')" v-model= "QuizDeveloper_Ans_value.Ans_9" />
        <label :for= "GetI18nData('Developer',9,'B')">{{$t('Quiz_Developer_9_choice_B')}}</label>
        <br>
        <input type="radio" :id= "GetI18nData('Developer',9,'C')" :value= "GetI18nData('Developer',9,'C')" v-model= "QuizDeveloper_Ans_value.Ans_9" />
        <label :for= "GetI18nData('Developer',9,'C')">{{$t('Quiz_Developer_9_choice_C')}}</label>
        <br>
      <br><br>


      <label>10. </label>
      <select v-model="QuizDeveloper_Ans_value.Ans_10">
        <option disabled value="">Please select one</option>
        <option>{{$t('Quiz_Developer_10_16_choice_A')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_B')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_C')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_D')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_E')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_F')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_G')}}</option>
      </select>
      <label v-html = " ' '+ $t('Quiz_Developer_10')"></label>
      <br><br>

      <label>11. </label>
      <select v-model="QuizDeveloper_Ans_value.Ans_11">
        <option disabled value="">Please select one</option>
        <option>{{$t('Quiz_Developer_10_16_choice_A')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_B')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_C')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_D')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_E')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_F')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_G')}}</option>
      </select>
      <label v-html = " ' ' + $t('Quiz_Developer_11')"></label>
      <br><br>

      <label>12. </label>
      <select v-model="QuizDeveloper_Ans_value.Ans_12">
        <option disabled value="">Please select one</option>
        <option>{{$t('Quiz_Developer_10_16_choice_A')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_B')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_C')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_D')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_E')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_F')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_G')}}</option>
      </select>
      <label v-html = " ' ' + $t('Quiz_Developer_12')"></label>
      <br><br>

      <label>13. </label>
      <select v-model="QuizDeveloper_Ans_value.Ans_13">
        <option disabled value="">Please select one</option>
        <option>{{$t('Quiz_Developer_10_16_choice_A')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_B')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_C')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_D')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_E')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_F')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_G')}}</option>
      </select>
      <label v-html = " ' ' + $t('Quiz_Developer_13')"></label>
      <br><br>

      <label>14. </label>
      <select v-model="QuizDeveloper_Ans_value.Ans_14">
        <option disabled value="">Please select one</option>
        <option>{{$t('Quiz_Developer_10_16_choice_A')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_B')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_C')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_D')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_E')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_F')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_G')}}</option>
      </select>
      <label v-html = " ' ' + $t('Quiz_Developer_14')"></label>
      <br><br>

      <label>15. </label>
      <select v-model="QuizDeveloper_Ans_value.Ans_15">
        <option disabled value="">Please select one</option>
        <option>{{$t('Quiz_Developer_10_16_choice_A')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_B')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_C')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_D')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_E')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_F')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_G')}}</option>
      </select>
      <label v-html = " ' ' + $t('Quiz_Developer_15')"></label>
      <br><br>

      <label>16. </label>
      <select v-model="QuizDeveloper_Ans_value.Ans_16">
        <option disabled value="">Please select one</option>
        <option>{{$t('Quiz_Developer_10_16_choice_A')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_B')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_C')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_D')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_E')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_F')}}</option>
        <option>{{$t('Quiz_Developer_10_16_choice_G')}}</option>
      </select>
      <label v-html = " ' ' + $t('Quiz_Developer_16')"></label>
      <br><br>
      <a v-on:click= "Submit" class="btn btn-primary">Submit</a>
      <br><br>
    </div>
    <div class="shape1"><img src="../../assets/img/shape1.png" alt="shape"></div>
    <div class="shape2 rotateme"><img src="../../assets/img/shape2.svg" alt="shape"></div>
    <div class="shape3"><img src="../../assets/img/shape3.svg" alt="shape"></div>
    <div class="shape4"><img src="../../assets/img/shape4.svg" alt="shape"></div>
    <div class="shape5"><img src="../../assets/img/shape5.png" alt="shape"></div>
    <div class="shape6 rotateme"><img src="../../assets/img/shape4.svg" alt="shape"></div>
    <div class="shape7"><img src="../../assets/img/shape4.svg" alt="shape"></div>
    <div class="shape8 rotateme"><img src="../../assets/img/shape2.svg" alt="shape"></div>
  </div>

  
    
</template>

<script>
export default {
  name: 'QuizView',
  data(){
    const QuizMath_Ans_value ={
      Ans_1:"",
      Ans_2:"",
      Ans_3:"",
      Ans_4:"",
      Ans_5:"",
    };
    
    const QuizMath_Ans_solve ={
        Ans_1: this.$i18n.t('Quiz_Math_1_Ans'),
        Ans_2: this.$i18n.t('Quiz_Math_2_Ans'),
        Ans_3: this.$i18n.t('Quiz_Math_3_Ans'),
        Ans_4: this.$i18n.t('Quiz_Math_4_Ans'),
        Ans_5: this.$i18n.t('Quiz_Math_5_Ans'),
      };
     const QuizDeveloper_Ans_value ={
        Ans_1:  "",
        Ans_2:  "",
        Ans_3:  "",
        Ans_4:  "",
        Ans_5:  "",
        Ans_6:  "",
        Ans_7:  "",
        Ans_8:  "",
        Ans_9:  "",
        Ans_10:  "",
        Ans_11:  "",
        Ans_12:  "",
        Ans_13:  "",
        Ans_14:  "",
        Ans_15:  "",
        Ans_16:  "",
      };
      const QuizDeveloper_Ans_solve ={
        Ans_1: this.$i18n.t('Quiz_Developer_1_Ans'),
        Ans_2: this.$i18n.t('Quiz_Developer_2_Ans'),
        Ans_3: this.$i18n.t('Quiz_Developer_3_Ans'),
        Ans_4: this.$i18n.t('Quiz_Developer_4_Ans'),
        Ans_5: this.$i18n.t('Quiz_Developer_5_Ans'),
        Ans_6: this.$i18n.t('Quiz_Developer_6_Ans'),
        Ans_7: this.$i18n.t('Quiz_Developer_7_Ans'),
        Ans_8: this.$i18n.t('Quiz_Developer_8_Ans'),
        Ans_9: this.$i18n.t('Quiz_Developer_9_Ans'),
        Ans_10: this.$i18n.t('Quiz_Developer_10_Ans'),
        Ans_11: this.$i18n.t('Quiz_Developer_11_Ans'),
        Ans_12: this.$i18n.t('Quiz_Developer_12_Ans'),
        Ans_13: this.$i18n.t('Quiz_Developer_13_Ans'),
        Ans_14: this.$i18n.t('Quiz_Developer_14_Ans'),
        Ans_15: this.$i18n.t('Quiz_Developer_15_Ans'),
        Ans_16: this.$i18n.t('Quiz_Developer_16_Ans'),
      };
    return{
      QuizMath_Ans_value,
      QuizMath_Ans_solve,
      QuizDeveloper_Ans_value,
      QuizDeveloper_Ans_solve,
    };
  },
  methods:{
    QuizMath1_Ans(event){
      this.QuizMath_Ans_value.Ans_1 = event.target.value;
    //   console.log(Object.values(this.QuizMath_Ans_value));
    },
    QuizMath2_Ans(event){
      this.QuizMath_Ans_value.Ans_2 = event.target.value;  
    },
    QuizMath3_Ans(event){
      this.QuizMath_Ans_value.Ans_3 = event.target.value;  
    },
    QuizMath4_Ans(event){
      this.QuizMath_Ans_value.Ans_4 = event.target.value;  
    },
    Submit(){
      let result = 0;
      for (let i =0;i < Object.values(this.QuizMath_Ans_value).length;i++){
        if(Object.values(this.QuizMath_Ans_value)[i] == Object.values(this.QuizMath_Ans_solve)[i]){
          result = result + 1;       
        }
      }
      for (let i =0;i < Object.values(this.QuizDeveloper_Ans_value).length;i++){
        if(Object.values(this.QuizDeveloper_Ans_value)[i] == Object.values(this.QuizDeveloper_Ans_solve)[i]){
          result = result + 1;       
        }
      }
      // return result
      console.log("result : "+result);
      //   console.log(this.QuizMath_Ans_value_Ans_1 ==Object.values(this.QuizMath_Ans_solve)[0]);
      console.log(this.QuizDeveloper_Ans_value);
      alert("ขอบคุณสำหรับคำตอบ ทางทีมงานจะแจ้งผลการเรียน ทางอีเมลล์ และแนะนำการเรียนต่อไป");
    },
     GetI18nData(type,number,choice){
      const cmd = "Quiz_"+ String(type)+ "_" + number + "_choice_"+ String(choice);
      return this.$i18n.t(cmd);     
    },
  }
}
</script>

<style>
</style>