<template>
    <div class="iot-cta-area bg-0f054b">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="cta-iot-img">
                        <img src= "../../../assets/img/cta-shape2.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="cta-iot-content" >
                        <h3>{{ $t('Guidance_head') }}</h3>
                        <p>{{ $t('Guidance_Description') }}</p>
                        <a @click= "clickGetStarted" class="btn btn-primary">Get Started!</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="circle-box"><img src="../../../assets/img/circle.png" alt="image" ></div>
        <div class="cta-shape"><img src="../../../assets/img/cta-shape.png" alt="image"></div>
    </div>
</template>

<script>
import UserService from '@/utils/userAPI.js';
export default {
    name: 'Guidance',
    data(){
        return{
            GuidanceImg : require("@/assets/img/talDemy/Guidance.png"),
            GuidanceImgSize : 400,
            }
        },
    methods:{
        clickGetStarted(){
            const Type = this.$store.state.Type;          
            const testStatus = this.$store.state.testStatus;

            if(this.$store.state.token){
                UserService.getInfo(this.$store.state.token)
                .then((response) =>{

                    if(response.status === 500){
                        window.location = "/NotFound500";
                        return ;
                    }

                    if(Type == "Recruiter" ){
                        if (typeof (response.data.info.personalEmail) != "undefined"){                      
                            this.$store.commit("setRegisterStatus",true);                                    
                        }
                        else{ // not info
                             this.$store.commit("setRegisterStatus",false); 
                             window.location= '/info';
                             return ; 
                        }
                        
                    }
                    else if(Type == "Student"){   
                        if (typeof (response.data.info.personalEmail) != "undefined"){
                            this.$store.commit("setRegisterStatus",true);
                        }
                        else{ // not info
                            this.$store.commit("setRegisterStatus",false); 
                             window.location= '/info';
                             return ; 
                        }
                    }
                   
                 })
                .catch((error) => {console.log(error);
                    if(error.response.status == 500){
                        window.location = "/NotFound500";
                        return ;
                    } 
                })  

                
                .then(() =>{
                    const popUpData = this.$i18n.t('popUpHome');

                    if(this.$store.state.registerStatus == true && Type == "Recruiter"){
                        // alert("เนื้อหานี้ มีไว้สำหรับนักเรียนเท่านั้น");                      
                         alert(popUpData);
                    }
                
                    else if(this.$store.state.registerStatus == true  && Type == "Student" && testStatus == false){
                        // alert("ทางทีมงานกำลังจัดชุดการเรียนรู้ที่เหมาะสมกับคุณกรุณาอดใจรอสักครู่\nหากอยากรู้คะแนนสอบติดต่อ support@taldemy.com");
                        alert(popUpData);
                    }
                    else if(this.$store.state.registerStatus == true  && Type == "Student" && testStatus == true){
                        // alert("ทางทีมงานกำลังจัดชุดการเรียนรู้ที่เหมาะสมกับคุณกรุณาอดใจรอสักครู่\nหากอยากรู้คะแนนสอบติดต่อ support@taldemy.com");
                        alert(popUpData);
                    }
                });
                
            }
            else{
                // if(this.$store.state.registerStatus == false  && Type == null){
                window.location= '/whoAreYou';  
            }
        }           
        //    default:
        //     window.location= '/whoAreYou';  
        
    }    

}
</script>