import { createStore } from "vuex";
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default createStore({
  plugins: [vuexLocal.plugin],
  state: {
    login_State: false,
    registerStatus:false,
    userID: "",
    Type: "",
    testStatus: false,
    language: "en",
    userImgURl : "",
    token:"",
  },

  getters: {
     login_State:state =>state.login_State,
     registerStatus:state =>state.registerStatus,
     userID:state =>state.userID,
     Type:state =>state.Type,
     testStatus: state =>state.testStatus,
     language:state =>state.language,
     userImgURl:state =>state.userImgURl,
     token:state =>state.token,

  },
  mutations: {
    setLogin(state,login_State){
      state.login_State = login_State;
    },
    setRegisterStatus(state,registerStatus){
      state.registerStatus = registerStatus;
    },
    setUserID(state,userID){
      state.userID = userID;
    },
    setType(state,Type){
      state.Type = Type;
    },
    setLanguage(state,language){
      state.language = language;
    },
    testStatus(state,testStatus){
      state.testStatus = testStatus;
    },
    setUserImgURl(state,userImgURl){
      state.userImgURl = userImgURl;
    },
    setToken(state,token){
      state.token = token;
    },
  },
  actions: {},
});
