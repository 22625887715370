import axios from 'axios'
const BASE_URL = "https://c1ao0upmrf.execute-api.ap-southeast-1.amazonaws.com/prod";
class UserService{
    login(auth){
        return axios.post(BASE_URL+"/users/login",auth)
    }

    register(auth){
        return axios.put(BASE_URL+"/users/register",auth)
    }
    
    getInfo(token){
        return axios.get(BASE_URL+"/users/info",{
                    headers:{
                        Authorization: 'Bearer '+ token,
                    }
                })
    }

    update(token,data){
        return axios.put(BASE_URL+"/users/update",data,{
            headers:{
                Authorization: 'Bearer '+ token,
            }
        })
    }
}
export default new UserService();