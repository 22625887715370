<template>
    <div class = "rootLogin">       
        <!-- Start Page Title -->
        <div class="page-title-area">
            <div class="d-table">
                <div class= "popUp">
                    <div class="container">
                        <h1>WhoAreYou</h1>
                    </div>
                </div>
			</div>			
			<div class="shape1"><img src="../../assets/img/shape1.png" alt="shape"></div>
			<div class="shape2 rotateme"><img src="../../assets/img/shape2.svg" alt="shape"></div>
			<div class="shape3"><img src="../../assets/img/shape3.svg" alt="shape"></div>
			<div class="shape4"><img src="../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape5"><img src="../../assets/img/shape5.png" alt="shape"></div>
			<div class="shape6 rotateme"><img src="../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape7"><img src="../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape8 rotateme"><img src="../../assets/img/shape2.svg" alt="shape"></div>
        </div>
        <!-- End Page Title -->
       

        <!-- Start Contact Info Area -->
		<section class="contact-info-area ptb-80">
            <div class="container" >
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-6"  style="margin-left: auto;" @click= "setUserType('Student')">
                        <div class="contact-info-box">
                            <!-- <div class="icon"> -->
                                <!-- <vue-feather type="mail"></vue-feather> -->
                            <!-- </div> -->
                            <img :src = "StudentLogin" :width= "ImgLoginSize" :height = "ImgLoginSize">    
                            <h2>For Student</h2>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6" style="margin-right: auto;" @click= "setUserType('Recruiter')">
                        <div class="contact-info-box">
                            <img :src = "RecruiterLogin" :width= "ImgLoginSize" :height = "ImgLoginSize">  
                            <h2>For Recruiter</h2>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        <!-- End Contact Info Area -->

    <!-- Start PopUp Area -->
      
    <teleport to ="body">              
        <div class="modelRecruiter" v-if = "isOpen">       
          <div>
            <a @click = "isOpen = false" class="close" tabindex="0" role="button">close</a>
            <h1>Create your account</h1>            
            <section>
                <img class= "GoogleImag" :src = "GoogleLogin" :width= "ImgGoogleSize"  @click= 'handleSignIn' style= "cursor: pointer;">
                <br>
            </section>       

          </div> 
        </div>
    </teleport>
    <!-- End PopUp Area -->
      

    </div>
</template>

<script>
import {inject, ref} from "vue"
import UserService from '@/utils/userAPI.js';

export default {
    name: 'whoAreYouSignUp',
    data() {
    return {
        GetImgURL: "",
        GetuserId: "",
        GetauthId:"",
        Getsource:"",
        GetfirstName:"",
        GetlastName:"",
        Gettelephone:"",
        Getemail:"",
        Getrole: "",
        StudentLogin: require('@/assets/img/talDemy/student_4.png'),
        RecruiterLogin: require('@/assets/img/talDemy/recruitment_1.png'),   
        GoogleLogin: require('@/assets/img/talDemy/Sign_up_google.png'),
        ImgLoginSize: "128",
        ImgGoogleSize: "256",
        ImgLinkedInSize: "64",

     }
    },

    methods: {
        setUserType(type){
            this.isOpen = true;
            this.userType = type;       
        },

        async handleSignIn() {
        try {
            const googleUser = await this.$gAuth.signIn();
            // await this.$http.post('http://localhost:5000/', { code: googleUser, redirect_uri: 'postmessage' })
            // console.log(this.userType);
            if (!googleUser) {
            return null;
            }
            const id_token = googleUser.getAuthResponse().id_token;
            this.GetImgURL = googleUser.getBasicProfile().getImageUrl();
           UserService.register({
                authId: id_token,
                source:"google",
                role: this.userType
            })
            .then((response) => {
                if(response.status === 500){
                    window.location = "/NotFound500";
                    return ;
                } 
                this.$store.commit("setLogin",true);
                this.$store.commit("setType",this.userType);
                this.$store.commit("setToken",response.data.token);

                setTimeout(this.$store.commit("setUserImgURl",this.GetImgURL),100);
                console.log(response.status);

                if(response.status == 201){
                    window.location = "/info";
                }else if(response.status == 202){
                    this.$store.commit("setRegister",true);
                    this.$store.commit("setType",response.data.info.role);
                    window.location = "/home"
                }else{
                    window.location = "/home"
                }
                
            })
            .catch((error) =>{
                let path = "/NotFound"+error.response.status;
                window.location = path;
            })  

        } catch (error) {
                // console.log(error);        
                return error;
        }
      
        },

         async handleSignOut() {
            try {
                await this.$gAuth.signOut();
                this.user = '';
            } catch (error) {
                // console.log(error);
             }   
        },

        popitup(url) {
            const newwindow= window.open(url, 'name', 'toolbar=1,scrollbars=1,location=1,statusbar=0,menubar=1,resizable=1,width=800,height=600');
             if (window.focus) {
                newwindow.focus();
            }
            return false;
        }

    },

    setup() {  
        const isOpen = ref(false);
        const Vue3GoogleOauth = inject("Vue3GoogleOauth");
        return {
            isOpen,
            Vue3GoogleOauth,
        } 
    },


}
</script>


<style>
  .rootLogin{
    position: relative;
  }
  .modelRecruiter{
    position: absolute;
    top: 0;
    left:0;
    background-color: rgba(0, 0, 0, 0.1);
    width:100%;
    height: 100%;
    display: flex;
    justify-content:center;
    align-items:center;
  }
  .modelRecruiter div{
    position: relative;
    background-color: #fff;
    z-index: 2;
    padding: 50px;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px 0px, rgba(0, 0, 0, 0.22) 0px 15px 12px 0px;
    font-family: arial;
    font-weight: bold;
    font-size: 18px;
    margin: 0 auto;
    text-decoration: none;
    text-align: center;
    padding-left: 1vw;
    padding-right: 1vw;
    color: black;
  }

  .LinkedImag{
    margin-top: 10px;
  }

  .GoogleImag{
    margin-top: 10px; 
  }

.close {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 50px;
    height: 50px;
    font-size: 0;
}
.close:before, 
.close:after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2px;
    height: 20px;
    background-color: black;
    transform: rotate(45deg) translate(-50%, -50%);
    transform-origin: top left;
    transition: all 420ms;
    content: '';
}
.close:after {
    transform: rotate(-45deg) translate(-50%, -50%);
}
.close:hover:before, 
.close:hover:after {
    background-color: black;
}

  
</style>