<template>
    <div v-if="this.$store.state.Type == 'Student'">
        <studentForm/>
    </div>
    <div v-else-if = "this.$store.state.Type == 'Recruiter'">
        <recruiterForm/>
    </div>
    <div v-else class = "rootLogin">       
        <!-- Start Page Title -->
        <div class="page-title-area">
          <div class="container" >
                <div class="row" style="justify-content: center ;">
                    <h1> You are not logged in. </h1>        
                    <h2> Redirecting to homepage. </h2>
                </div>               
            </div>
        </div>		
        <!-- End Page Title -->
       

        <!-- Start Contact Info Area -->
		<section class="contact-info-area ptb-80">           
        </section>  
        <!-- End Contact Info Area -->

    </div>
</template>
  
<script>
import recruiterForm from "@/components/custom/recruiterForm.vue";
import studentForm from "@/components/custom/studentForm.vue";
export default {
    name: 'AccountInfo',
    components:{
        recruiterForm,
        studentForm
    },

    beforeMount(){
        const userType = this.$store.state.Type;
        console.log(userType);
    if(userType == null || userType == "")  {
        window.location= '/home';
        }
    }
}
</script>